<template>
  <div>
    <v-container fluid>

      <v-row>
        <v-col cols="12">
          <v-card class="shadow rounded-lg">
            <v-card-text class="pa-10">
              <v-row align="center">
                <v-col>
                  <h2 class="font-weight-medium py-4 secondary--text">Bienvenue à my Coca Cola.</h2>
                  <p class="fs-14">Outil de communication avec les POS</p>
                  <v-btn color="secondary" depressed to="/campaigns/create">
                    <v-icon left>mdi-plus</v-icon>
                    Nouvelle campagne
                  </v-btn>
                </v-col>

                <v-col class="text-lg-end text-center">
                  <img :src="require('@/assets/user.svg')" alt="" width="180"/>
                </v-col>

              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

<!--      <v-row>-->
<!--        <v-col cols="3">-->
<!--          <v-card class="shadow rounded-lg">-->
<!--            <v-card-text class="pa-6">-->
<!--              <div class="d-flex align-center justify-space-between">-->
<!--                <div>-->

<!--                  <v-skeleton-loader v-if="isLoading" type="text" width="100"/>-->
<!--                  <span v-else-->
<!--                        class="font-weight-medium d-block secondary&#45;&#45;text fs-18">{{ statistic.campaigns_count }}</span>-->

<!--                  <p class="mt-1 mb-0 ">Campagnes</p>-->
<!--                </div>-->
<!--                <v-spacer/>-->
<!--                <v-avatar class="bg-grad-primary" size="60">-->
<!--                  <v-icon color="primary" size="30">mdi-file-document-outline</v-icon>-->
<!--                </v-avatar>-->
<!--              </div>-->
<!--            </v-card-text>-->
<!--          </v-card>-->
<!--        </v-col>-->
<!--        <v-col cols="3">-->
<!--          <v-card class="shadow rounded-lg">-->
<!--            <v-card-text class="pa-6">-->
<!--              <div class="d-flex align-center justify-space-between">-->
<!--                <div>-->
<!--                  <v-skeleton-loader v-if="isLoading" type="text" width="100"/>-->
<!--                  <span v-else-->
<!--                        class="font-weight-medium d-block secondary&#45;&#45;text fs-18">{{ statistic.salepoints_count }}</span>-->
<!--                  <p class="mt-1 mb-0">propriétaires</p>-->
<!--                </div>-->
<!--                <v-spacer/>-->
<!--                <v-avatar class="bg-grad-primary" size="60">-->
<!--                  <v-icon color="primary" size="30">mdi-database-outline</v-icon>-->
<!--                </v-avatar>-->
<!--              </div>-->
<!--            </v-card-text>-->
<!--          </v-card>-->
<!--        </v-col>-->
<!--        <v-col cols="3">-->
<!--          <v-card class="shadow rounded-lg">-->
<!--            <v-card-text class="pa-6">-->
<!--              <div class="d-flex align-center justify-space-between">-->
<!--                <div>-->
<!--                  <v-skeleton-loader v-if="isLoading" type="text" width="100"/>-->
<!--                  <span v-else-->
<!--                        class="font-weight-medium d-block secondary&#45;&#45;text fs-18"> {{ statistic.sms_cost | toCurrency }} DA</span>-->
<!--                  <p class="mt-1 mb-0">Coût total</p>-->
<!--                </div>-->
<!--                <v-spacer/>-->
<!--                <v-avatar class="bg-grad-primary" size="60">-->
<!--                  <v-icon color="primary" size="30">mdi-currency-usd</v-icon>-->
<!--                </v-avatar>-->
<!--              </div>-->
<!--            </v-card-text>-->
<!--          </v-card>-->
<!--        </v-col>-->
<!--        <v-col cols="3">-->
<!--          <v-card class="shadow rounded-lg">-->
<!--            <v-card-text class="pa-6">-->
<!--              <div class="d-flex align-center justify-space-between">-->
<!--                <div>-->
<!--                  <v-skeleton-loader v-if="isLoading" type="text" width="100"/>-->
<!--                  <span v-else class="font-weight-medium d-block secondary&#45;&#45;text fs-18">{{ statistic.sms_count }}</span>-->
<!--                  <p class="mt-1 mb-0">SMS envoyé</p>-->
<!--                </div>-->
<!--                <v-spacer/>-->
<!--                <v-avatar class="bg-grad-primary" size="60">-->
<!--                  <v-icon color="primary" size="30">mdi-message-text-outline</v-icon>-->
<!--                </v-avatar>-->
<!--              </div>-->
<!--            </v-card-text>-->
<!--          </v-card>-->
<!--        </v-col>-->
<!--      </v-row>-->

<!--      <v-row>-->
<!--        <v-col cols="12">-->
<!--          <v-card class="shadow rounded-lg">-->
<!--            <v-card-text>-->
<!--              <Chart/>-->
<!--            </v-card-text>-->
<!--          </v-card>-->
<!--        </v-col>-->
<!--      </v-row>-->

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
// import Chart from "./components/Chart.vue";

export default {
    // components: {Chart},
    data() {
        return {
            isLoading: false,
            statistic: {},
        }
    },
    methods: {
        getStatistic() {
            this.isLoading = true
            HTTP.get('/dashboard').then((res) => {
                this.isLoading = false
                this.statistic = res.data.data
            }).catch(err => {
                this.isLoading = false
                console.log(err)
            })
        },
    },
    created() {
        // this.getStatistic()
    }
}
</script>

<style scoped>

</style>